* {
    -webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
    color-adjust: exact !important;                 /* Firefox 48 – 96 */
    print-color-adjust: exact !important;           /* Firefox 97+, Safari 15.4+ */
}

table.print-rx-form:first-child {
    margin-top: 1cm;
}

.rx-form {
    opacity: 0;
}

table.print-rx-form {
    width: 50%;
    text-align: left;
    border-collapse: collapse;
    margin-left: 1cm;
}

table.print-rx-form td {
    padding: 5px 10px;
    width: 50%;
    vertical-align: baseline;
}

table.print-rx-form tbody td {
    font-size: 12px;
}

table.print-rx-form tbody td .heading {
    font-weight: bold;
    margin-bottom: 0;
}

table.print-rx-form tbody td .value {
    margin-bottom: 0px;
    font-size: 12px;
}

.text-center {
    text-align: center;
}

table.print-rx-form tr.header {
    background-color: #eee;
    text-transform: uppercase;
    font-weight: bold;
}

table.print-rx-form tr.header td {
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
}

table.print-rx-form tr.section td {
    border: solid 1px #ccc;
}

.rush-case-border table.print-rx-form tr.section > td {
    border: solid 2px #000;
}

table.print-rx-form tr.section td.bl-0 {
    border-left: none !important;
}

table.print-rx-form tr.section td.br-0 {
    border-right: none !important;
}

table.print-rx-form tr.section td.bb-0 {
    border-bottom: none !important;
}

table.print-rx-form p.label {
    font-weight: bold;
    margin-bottom: 0;
}

table.print-rx-form .text-right {
    text-align: right;
}

table.print-rx-form td.sub-table {
    padding: 0
}

.sub-table table {
    width: 100%
}

table.print-rx-form td.sub-table tbody tr td {
    border: none;
    padding: 0 10px;
}

table.print-rx-form td.sub-table tbody tr td:first-child {
    border-right: solid 1px #ccc;
}

table.print-rx-form td.sub-table tbody tr td:last-child {
    border-left: solid 1px #ccc;
}

.rush-case-border table.print-rx-form td.sub-table tbody tr td:first-child {
    border-right: solid 2px #000;
}

.rush-case-border table td.sub-table tbody tr td:last-child {
    border-left: solid 2px #000;
}

.rush-case-border {
    padding: 0;
    border: 10px solid transparent;
    border-image-source: url("../../assets/images/print/rush.png");
    border-image-repeat: round;
    border-image-slice: 155;
    border-image-width: 40px;
    position: absolute;
    overflow: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

@media print {
    table.print-rx-form {
        width: calc(50% - 1cm);
        height: 50%;
        display: table;
    }
    .rx-form {
        opacity: 1;
    }
    @page { margin: 0; }
}
